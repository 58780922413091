/* @flow */
import { connect } from 'react-redux';

import { getAuthUserNickname } from '../../../../reducers/';

import Selection from './selection.jsx';

const mapStateToProps = (state) => {
  return {
    nickname: getAuthUserNickname(state),
  };
};

export default connect(mapStateToProps)(Selection);
