/* @flow */
// Libraries
import { useState, useEffect } from 'react';
import classnames from 'classnames';
import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'redux';
import get from 'lodash/get';

import { ThemeProvider } from '@eyeem-ui/theme';
import { Flex, Spinner, Box } from '@eyeem-ui/atoms';

import { dataLayerPageView } from '../../helpers/dataLayerUpdate';
import T from '../general/translate';
import UserGrid from '../general/userGrid';
import PaginatablePhotoGrid from '../general/photoGrid/paginatable';
import Subnavigation from '../general/subnavigation';
import Photo from '../general/photo';
import Link from '../general/link';
import EmptyState from '../general/emptyState';
import ErrorMsg from '../general/error';
import ErrorMeta from '../general/errorMeta';
import ProfileMeta from './profileMeta';
import ProfileContentFilterDropdown from './profileContentFilterDropdown';

// Direct Child Components
import MainButton from './mainButton';
import ProfileHeaderWrapper from './profileHeaderWrapper';
import ReleasesHeader from './releasesHeader';

const CONTENT_SPINNER = 'spinner';
const CONTENT_EMPTYSTATE = 'emptyState';
const CONTENT_PHOTOGRID = 'photoGrid';
const CONTENT_USERGRID = 'userGrid';

import {
  isOneOf,
  isPaginatableEmpty,
  deepMergePaginatables,
  getAlbumStepSize,
} from '../../helpers/tools';
import { GET_USER_PHOTOS, GET_USER } from '../../graphql/queries/user';
import { GET_WEB_BACKEND_ILLUSTRATIONS } from '../../graphql/queries/illustration';

import {
  ACTION_BAR_RELEASES,
  ACTION_BAR_REGULAR,
  PAGINATION_PARADIGM_AUTO,
  PAGINATION_PARADIGM_MANUAL,
} from '../../constants/misc';

import withRouter from '../../helpers/hoc/withRouter';

const HEIGHT_OF_HEADER = 321;

const paginatableNameToUrlSuffix = {
  illustrations: '/illustrations',
  photos: '/photos',
  followers: '/followers',
  followings: '/following',
  likedPhotos: '/likedphotos',
  marketPhotos: '/market',
  partnerPhotos: '/partner',
  releasesNeededPhotos: '/releases',
};

type Props = {
  auth: any,
  authUser: AuthUser,
  data: {
    user: EyeEmUser,
    refetch: Function,
    fetchMore: Function,
  } & ApolloQueryResult,
  deviceType: string,
  isAdmin: boolean,
  isBuyer: boolean,
  isEnterpriseCustomer: boolean,
  isMobile: boolean,
  isOwnProfile: boolean,
  isSeller: boolean,
  language: string,
  paginatable: Paginatable,
  illustrationsData: {
    illustrations: Paginatable,
  },
  paginatableName: string,
  profileData: { user: EyeEmUser } & ApolloQueryResult,
} & WithRouterProps;

const Profile = (props: Props) => {
  const [wasTrackedFor, setWasTrackedFor] = useState(null);
  const { paginatableName, data = {}, profileData, query } = props;
  const { user } = profileData;

  const [isPaging, setIsPaging] = useState(false);
  const isPhotoPaginatable = isOneOf(paginatableName, [
    'photos',
    'likedPhotos',
    'marketPhotos',
    'partnerPhotos',
    'releasesNeededPhotos',
  ]);
  const isUserPhotoPaginatable = isOneOf(paginatableName, [
    'marketPhotos',
    'partnerPhotos',
    'photos',
  ]);
  const isIllustrationsPaginatable = paginatableName === 'illustrations';
  const isPaginatableAsset = isPhotoPaginatable || isIllustrationsPaginatable;

  const isSubPagePaginatable = isOneOf(paginatableName, [
    'followers',
    'followings',
    'likedPhotos',
  ]);

  const trackPageView = async () => {
    await setWasTrackedFor(window.location.pathname + window.location.search);
    const trackingName =
      paginatableName === 'photos'
        ? 'photos'
        : paginatableNameToUrlSuffix[`${paginatableName}`].split('/')[1];
    const normalizedResponseBody = {
      requestInfo: {
        type: 'user',
        resource: user.nickname.toLowerCase(),
        url: window.location.pathname + window.location.search,
        tracking: {
          name: `user_${trackingName.toLowerCase()}`,
          pageOption: `pageIndex=${props.query.page || 1}`,
        },
      },
      auth: props.auth,
      user: [props.authUser, { ...user, statusCode: 200 }].filter(
        (item) => !!item
      ),
    };

    dataLayerPageView({
      body: normalizedResponseBody,
    });
  };

  useEffect(() => {
    // track only if the URL has changed
    // and when the data is available
    if (
      !data.loading &&
      user &&
      wasTrackedFor !== window.location.pathname + window.location.search
    ) {
      trackPageView();
    }
  }, [props.query.page, user, data.loading, paginatableName]);

  const makeApolloRequest = () => {
    const _data = isIllustrationsPaginatable
      ? { ...props.illustrationsData }
      : { ...data };
    setIsPaging(true);

    const currentGridData = isIllustrationsPaginatable
      ? props.illustrationsData
      : data;
    const offset = isIllustrationsPaginatable
      ? props.illustrationsData.webBackendIllustrations.items.length
      : data.user.photos.items.length;

    // Photographers get 'auto' pagination (infinite scroll)
    return currentGridData
      .fetchMore({
        variables: {
          offset,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          // BUG: Apollo returns 'undefined' for prev
          if (!fetchMoreResult) return prev;

          return Object.assign(
            {},
            prev,
            deepMergePaginatables(_data, fetchMoreResult)
          );
        },
      })
      .finally(() => setIsPaging(false));
  };

  useEffect(() => {
    setIsPaging(true);
    profileData.refetch().then(() => setIsPaging(false));
  }, [props.nickname]);

  useEffect(() => {
    if (isPaginatableAsset && user?.id && !isIllustrationsPaginatable) {
      setIsPaging(true);
      data.refetch().then(() => setIsPaging(false));
    }
  }, [paginatableName, user, query.page]);

  if (data.error || profileData.error) {
    let statusCode;
    let errorMessage;

    // Flattening to the most recent error.
    (data.error || profileData.error).graphQLErrors.forEach((error) => {
      statusCode = error.extensions.apiStatusCode;
      errorMessage = error.message;
    });

    return (
      <>
        <ErrorMeta statusCode={statusCode} />
        <ErrorMsg
          code={statusCode}
          message={errorMessage}
          resourceType="user"
        />
      </>
    );
  }

  const paginatable = isIllustrationsPaginatable
    ? get(props, 'illustrationsData.webBackendIllustrations', null)
    : get(data, 'user.photos', null);

  const getTranslationPrefix = () => {
    let prefix = `user.empty.${
      props.isOwnProfile ? 'own' : 'foreign'
    }.${paginatableName}`;
    if (paginatableName === 'marketPhotos' && props.isOwnProfile) {
      prefix = `${prefix}.${props.isSeller ? 'seller' : 'nonSeller'}`;
    }
    return prefix;
  };

  const secondaryNavItems = () => {
    const navItems = [];

    if (hasFollowers) {
      navItems.push({
        translationKey: 'user.followers',
        active: paginatableName === 'followers',
        href:
          user &&
          `/u/${user.nickname.toLowerCase()}${
            paginatableNameToUrlSuffix.followers
          }`,
        count: get(user, 'totalFollowers') || 0,
      });
    }

    if (hasFriends) {
      navItems.push({
        translationKey: 'user.followings',
        active: paginatableName === 'followings',
        href:
          user &&
          `/u/${user.nickname.toLowerCase()}${
            paginatableNameToUrlSuffix.followings
          }`,
        count: get(user, 'totalFriends') || 0,
      });
    }

    if (!props.isMobile && (props.isOwnProfile || props.isAdmin)) {
      navItems.push({
        translationKey: 'user.likedPhotos',
        active: paginatableName === 'likedPhotos',
        href:
          user &&
          `/u/${user.nickname.toLowerCase()}${
            paginatableNameToUrlSuffix.likedPhotos
          }`,
        count: get(user, 'totalLikedPhotos') || 0,
      });
    }

    return navItems;
  };

  const primaryNavItems = () => {
    const illustrationTotal =
      props.illustrationsData?.webBackendIllustrations?.total || 0;

    const navItems = [
      {
        translationKey: 'user.photos',
        active: isUserPhotoPaginatable,
        href:
          user &&
          `/u/${user.nickname.toLowerCase()}${
            paginatableNameToUrlSuffix.photos
          }`,
        scrollToY: HEIGHT_OF_HEADER,
        count: get(user, 'totalPhotos') || 0,
      },
    ];

    // TODO_ILLUSTRATIONS : remove conditional after we go live
    navItems.push({
      translationKey: 'user.illustrations',
      active: paginatableName === 'illustrations',
      href:
        user &&
        `/u/${user.nickname.toLowerCase()}${
          paginatableNameToUrlSuffix.illustrations
        }`,
      scrollToY: HEIGHT_OF_HEADER,
      count: illustrationTotal,
    });

    return navItems;
  };

  const subnavItems = () => {
    if (isOneOf(paginatableName, ['followers', 'followings', 'likedPhotos'])) {
      return secondaryNavItems();
    }
    return primaryNavItems();
  };

  const isOnReleasesNeeded = () => paginatableName === 'releasesNeededPhotos';

  const { page } = props.query;

  const blockedOrRestricted = user && (user.blocked || user.restricted);

  const showProfileHeader =
    user && !isSubPagePaginatable && paginatableName !== 'releasesNeededPhotos';
  const showContentFilterDropdown = isUserPhotoPaginatable;
  const showReleasesHeader =
    paginatableName === 'releasesNeededPhotos' &&
    paginatable &&
    paginatable.total > 0;

  const showSubnavigation =
    user && !blockedOrRestricted && paginatableName !== 'releasesNeededPhotos';

  const hasFollowers = get(user, 'totalFollowers', 0) > 0;
  const hasFriends = get(user, 'totalFriends', 0) > 0;
  const hasLikedPhotos = get(user, 'totalLikedPhotos', 0) > 0;

  // what to show on main area?
  const whatContent = () => {
    if (data.loading) {
      return CONTENT_SPINNER;
    }
    if (
      (paginatable && isPaginatableEmpty(paginatable)) ||
      (!hasFollowers && paginatableName === 'followers') ||
      (!hasFriends && paginatableName === 'followings') ||
      (!hasLikedPhotos && paginatableName === 'likedPhotos')
    ) {
      return CONTENT_EMPTYSTATE;
    }

    if (user && paginatable && paginatable.total > 0 && isPaginatableAsset) {
      return CONTENT_PHOTOGRID;
    }

    if (
      user &&
      (hasFollowers || hasFriends) &&
      isOneOf(paginatableName, ['followers', 'followings'])
    ) {
      return CONTENT_USERGRID;
    }
    return false;
  };

  const button = user && !props.isEnterpriseCustomer && (
    <MainButton
      isAuthUser={props.isOwnProfile}
      color="ghostWhite"
      size="small"
      user={user}
    />
  );

  const userThumbUrl =
    user && user.thumbUrl && !user.blocked && !user.restricted
      ? user.thumbUrl
      : '/node-static/img/profile-placeholder.jpg';

  const sideInfoRight = user && (
    <Flex
      className={classnames(
        'subnav_sideInfo subnav_sideInfo-right',
        'header_subnavButton',
        { 'subnav_sideInfo-dontHide': isSubPagePaginatable }
      )}>
      <Box mr={showContentFilterDropdown && '140px'}>{button}</Box>
    </Flex>
  );

  const sideInfoLeft = user && (
    <div
      className={classnames(
        'subnav_sideInfo subnav_sideInfo-left',
        'header_subnavInfo',
        { 'subnav_sideInfo-dontHide': isSubPagePaginatable }
      )}>
      <Link
        href={`/u/${user && user.nickname.toLowerCase()}`}
        className="g_media">
        <Photo
          url={userThumbUrl}
          size1="circle"
          size2={32}
          className="header_subnavInfo_thumb g_media-left"
        />
        <div className="g_media-body">
          <p className="typo_pStatic typo-noMargin typo-color-white header_subnavInfo_text">
            {user.fullname}
          </p>
          <p className="typo_pMin typo-noMargin typo-color-grey3 header_subnavInfo_text">
            @{user.nickname}
          </p>
        </div>
      </Link>
    </div>
  );

  return (
    <div>
      <ProfileMeta
        isBuyer={props.isBuyer}
        language={props.language}
        paginatable={paginatable}
        user={user}
      />
      {showProfileHeader && (
        <ProfileHeaderWrapper
          user={user}
          navItems={secondaryNavItems()}
          isEnterpriseCustomer={props.isEnterpriseCustomer}
          isAuthUser={props.isOwnProfile}
          isMobile={props.isMobile}
        />
      )}
      {showSubnavigation && (
        <Subnavigation
          centered
          isMobile={props.isMobile}
          isOwnProfile={props.isOwnProfile}
          navItems={subnavItems()}
          navigate={props.navigate}
          night
          noCollapsible
          paginatableName={paginatableName}
          showContentFilterDropdown={showContentFilterDropdown}
          sideInfoLeft={sideInfoLeft}
          sideInfoRight={sideInfoRight}
          userNickname={user.nickname}
        />
      )}
      {showReleasesHeader && (
        <ReleasesHeader total={paginatable.total} photos={paginatable.items} />
      )}
      {props.isMobile && showContentFilterDropdown && (
        <Box mb={2} mx={3} mt={3}>
          <ProfileContentFilterDropdown
            isOwnProfile={props.isOwnProfile}
            userNickname={user?.nickname}
            navigate={props.navigate}
            paginatableName={paginatableName}
            showContentFilterDropdown={showContentFilterDropdown}
          />
        </Box>
      )}

      {whatContent() === CONTENT_PHOTOGRID && (
        <PaginatablePhotoGrid
          className="grid-topMargin"
          fetchPaginatable={makeApolloRequest}
          paginationParadigm={
            (paginatableName === 'releasesNeededPhotos' &&
              PAGINATION_PARADIGM_AUTO) ||
            (props.isOwnProfile && PAGINATION_PARADIGM_MANUAL)
          }
          actionBar={
            paginatableName === 'releasesNeededPhotos'
              ? ACTION_BAR_RELEASES
              : ACTION_BAR_REGULAR
          }
          page={page}
          paginatable={{
            ...paginatable,
            resourceType: 'user',
            resourceId: user.nickname,
            paginatableName,
            paginatableType: 'photo',
          }}
          conversionEventOption={{ sourceId: user.id }}
          feedName={user.fullname}
          initialUrl={`/u/${user.nickname.toLowerCase()}${
            paginatableNameToUrlSuffix[paginatableName]
          }`}
        />
      )}
      {whatContent() === CONTENT_USERGRID && (
        <UserGrid
          paginatableName={paginatableName}
          resourceId={user.nickname}
          user={user}
        />
      )}
      {whatContent() === CONTENT_EMPTYSTATE && (
        <EmptyState
          noSublineLink
          centerContentVertically={isOnReleasesNeeded()}
          button={props.isOwnProfile}
          resourceId={user && user.nickname}
          noSubline={paginatableName === 'partnerPhotos'}
          name={user && (user.fullname || `@${user.nickname}`)}
          translationPrefix={getTranslationPrefix()}
        />
      )}
      {(whatContent() === CONTENT_SPINNER || isPaging) && (
        <span>
          <ThemeProvider>
            <Flex justifyContent="center" p="4">
              <Spinner inline />
            </Flex>
          </ThemeProvider>
        </span>
      )}
      {blockedOrRestricted && (
        <div className="g_centered_wrapper empty-grid">
          <div className="g_centered">
            <T component="h2">
              error.
              {user.blocked ? 'blocked' : 'restricted'}
            </T>
          </div>
        </div>
      )}
    </div>
  );
};

export default compose(
  withRouter,
  graphql(GET_USER, {
    name: 'profileData',
    options: ({ nickname }) => ({
      variables: {
        nickname,
      },
    }),
  }),
  graphql(GET_WEB_BACKEND_ILLUSTRATIONS, {
    name: 'illustrationsData',
    options: ({ query, isBuyer, isOwnProfile, profileData }) => ({
      fetchPolicy: 'network-only',
      variables: {
        offset: query.page
          ? (Number(query.page) - 1) * getAlbumStepSize(isBuyer || isOwnProfile)
          : 0,
        limit: getAlbumStepSize(isBuyer || isOwnProfile),
        userId: profileData?.user?.id,
      },
    }),
    skip: ({ profileData }) => !profileData?.user?.id,
  }),
  graphql(GET_USER_PHOTOS, {
    skip: ({ paginatableName }) =>
      !isOneOf(paginatableName, [
        'photos',
        'likedPhotos',
        'marketPhotos',
        'partnerPhotos',
        'releasesNeededPhotos',
      ]),
    options: ({ paginatableName, nickname, isBuyer, query, isOwnProfile }) => ({
      variables: {
        nickname,
        offset: query.page
          ? (Number(query.page) - 1) * getAlbumStepSize(isBuyer || isOwnProfile)
          : 0,
        limit: getAlbumStepSize(isBuyer || isOwnProfile),
        paginatableName,
        isBuyer,
      },
    }),
  })
)(Profile);
