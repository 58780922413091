/* @flow */
import { connect } from 'react-redux';

import {
  getDeviceType,
  getIsMobile,
  getAuthUser,
  getIsSeller,
  getIsBuyer,
  getIsEnterpriseCustomer,
  getIsAdmin,
  getLanguage,
} from '../../reducers/';

import { isAuthUser } from '../../helpers/accounts';

import { USER_PAGINATABLE_NAME_MAP } from '../../constants/misc';

import Profile from './profile';

const mapStateToProps = (state, ownProps) => {
  const paginatableName =
    USER_PAGINATABLE_NAME_MAP[ownProps.paginatableName] ||
    ownProps.paginatableName;

  const authUser = getAuthUser(state);

  return {
    auth: state.auth,
    authUser,
    deviceType: getDeviceType(state),
    isAdmin: getIsAdmin(state),
    isBuyer: getIsBuyer(state),
    isEnterpriseCustomer: getIsEnterpriseCustomer(state),
    isMobile: getIsMobile(state),
    isOwnProfile: isAuthUser(ownProps, authUser),
    isSeller: getIsSeller(state),
    language: getLanguage(state),
    paginatableName,
  };
};

export default connect(mapStateToProps)(Profile);
