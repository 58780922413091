/* @flow */
import type { Node } from 'react';
import t from 'counterpart';
import styled, { css } from 'styled-components';
import { styleUtils } from 'eyeem-components';
import createComponentFromTagProp from 'react-create-component-from-tag-prop';
import { Flex, Box, Text } from '@eyeem-ui/atoms';
import { media } from '@eyeem-ui/theme';

import Photo from './photo/';
import PhotoFullWidth from './photoFullWidth/';

const ComponentFromTagProp = createComponentFromTagProp({
  tag: 'div',
  prop: 'renderAs',
  propsToOmit: ['renderAs'],
});

const Wrapper = styled(({ noBorder, textLeft, withBackground, ...rest }) => (
  <ComponentFromTagProp {...rest} />
))`
  text-align: left;
  position: relative;
  padding: 32px;
  border-bottom: ${styleUtils.fromPropsTernary(
    'noBorder',
    'none',
    css`1px solid ${styleUtils.fromTheme('colors.greys.grey5')};`
  )};
  ${styleUtils.fromPropsTernary(
    'withBackground',
    css`
      padding-top: calc(32px + ${styleUtils.fromTheme('navHeight')});
      margin-top: -${styleUtils.fromTheme('navHeight')};
    `,
    ''
  )} ${styleUtils.fromPropsTernary(
    'textLeft',
    css`
      text-align: left;
    `,
    ''
  )};
`;

const Thumb = styled(({ animated, noBorder, ...rest }) => <Photo {...rest} />)`
  ${styleUtils.fromPropsTernary(
    'animated',
    css`
      box-shadow: 0 0 0
        ${styleUtils.fromTheme('colors.uncategorized.unnamed12')};
      animation: pulseAndShadow 1s infinite;
    `,
    ''
  )} margin-bottom: 8px;

  width: ${styleUtils.fromProps('size2')}px;
  height: ${styleUtils.fromProps('size2')}px;
  border: ${styleUtils.fromPropsTernary(
    'noBorder',
    'none',
    css`2px solid ${styleUtils.fromTheme('colors.whites.white')}`
  )};
  @media ${media.xlargeUp} {
    float: left;
    margin-right: ${styleUtils.fromProps('thumbMargin')}px;
    margin-bottom: 0;

    width: ${styleUtils.fromProps('size2')}px;
    height: ${styleUtils.fromProps('size2')}px;
  }
`;

const DescriptionSection = styled(({ withThumb, ...rest }) => (
  <div {...rest} />
))`
  display: inline-block;
  width: 100%;
  @media ${media.xlargeUp} {
    ${styleUtils.fromPropsTernary(
      'withThumb',
      css`
        width: calc(100% - 128px);
      `,
      ''
    )}
  }
`;

const ButtonSection = styled(Flex)`
  width: 100%;
  margin-top: 40px;
  @media ${media.xlargeUp} {
    margin-top: 0;
    width: auto;
    position: absolute;
    right: 32px;
    bottom: 32px;
  }
`;

const BelowSection = styled.div`
  width: 100%;
`;

function PlainHeader(props: {
  noTranslate?: boolean,
  noBorder?: boolean,
  headline?: false | string | Node,
  text1?: false | string | Node,
  text2?: false | string | Node,
  thumbUrl?: string,
  buttonSectionContent?: false | Node,
  belowSectionContent?: false | Node,
  animateThumb?: boolean,
  previewUrl?: string,
  onDark?: boolean,
  textLeftOnAllScreens?: boolean,
  thumbSize?: number,
  thumbMargin?: number,
  textColor?: string,
  condensed?: boolean,
}) {
  const content = (
    <Flex flexDirection="column">
      <Box>
        {props.thumbUrl && (
          <Thumb
            noBorder={props.noBorder}
            animated={props.animateThumb}
            thumbMargin={props.thumbMargin}
            size1="circle"
            size2={props.thumbSize}
            url={props.thumbUrl}
          />
        )}
        {props.headline && (
          <DescriptionSection withThumb={!!props.thumbUrl}>
            <Text as="h1" bold>
              {props.noTranslate ? props.headline : t(props.headline)}
            </Text>

            {props.text1 && (
              <Box mt={!props.condensed && 1}>
                <Text color="grey50">
                  {props.noTranslate ? props.text1 : t(props.text1)}
                </Text>
              </Box>
            )}

            {props.text2 && <Text color="grey50">{props.text2}</Text>}
          </DescriptionSection>
        )}
        {props.buttonSectionContent && (
          <ButtonSection justifyContent="flex-end" mt={[4, 0]}>
            {props.buttonSectionContent}
          </ButtonSection>
        )}
      </Box>
      {props.belowSectionContent && (
        <BelowSection>{props.belowSectionContent}</BelowSection>
      )}
    </Flex>
  );

  return (
    <Wrapper
      renderAs={props.previewUrl ? PhotoFullWidth : 'div'}
      srcSet={props.previewUrl ? true : undefined}
      withBackground={!!props.previewUrl}
      noBorder={props.noBorder}
      textLeft={props.textLeftOnAllScreens}
      url={props.previewUrl}>
      {content}
    </Wrapper>
  );
}

PlainHeader.defaultProps = {
  noBorder: false,
  noTranslate: false,
  onDark: false,
  textLeftOnAllScreens: false,
  headline: undefined,
  text1: undefined,
  text2: undefined,
  thumbUrl: undefined,
  buttonSectionContent: undefined,
  belowSectionContent: undefined,
  animateThumb: false,
  previewUrl: undefined,
  thumbSize: 104,
  thumbMargin: 24,
  textColor: undefined,
  condensed: false,
};

export default PlainHeader;
