/* @flow */
// Libraries
import t from 'counterpart';
import { Button } from '@eyeem-ui/atoms';
import { ThemeProvider } from '@eyeem-ui/theme';

// General Components
import UserFollowBtn from '../../general/userFollowBtn/';
import Link from '../../general/link';

function MainButton(props: {
  user: EyeEmUser,
  className: string,
  isSeller: boolean,
  isAuthUser: boolean,
  isBuyer: boolean,
  color: string,
  size: string,
}) {
  if (props.isAuthUser) {
    if (props.isSeller) {
      return (
        <ThemeProvider>
          <Link href="/market/sell/payout">
            <Button title={t('user.earnings')} className={props.className}>
              {t('user.earnings')}
            </Button>
          </Link>
        </ThemeProvider>
      );
    }
    return (
      <ThemeProvider>
        <Link href="/market/sell/start">
          <Button title={t('user.joinMarket')} className={props.className}>
            {t('user.joinMarket')}
          </Button>
        </Link>
      </ThemeProvider>
    );
  }

  if (!props.isBuyer) {
    return (
      <UserFollowBtn
        size={props.size}
        color={props.color}
        className={props.className}
        eventPosition="user"
        user={props.user}
      />
    );
  }

  return null;
}

export default MainButton;
