/** @flow */
/** @jsxRuntime classic */
/** @jsx jsx */
import t from 'counterpart';
import { jsx } from 'theme-ui'; // eslint-disable-line
import { Text, Flex, Box, Tag } from '@eyeem-ui/atoms';
import { ThemeProvider } from '@eyeem-ui/theme';
import styled from '@emotion/styled';

import Header from '../general/header.jsx';
import Link from '../general/link/';
import MainButton from './mainButton/';

import { abbreviate } from '../../helpers/tools';
import { TEST_CREATOR_HEADER_HOMEPAGE } from '../../constants/pageObjectSelectors';

const StyledLinkText = styled(Text)`
  &:hover {
    color: ${({ theme }) => theme.colors.grey50};
  }
  transition: color 0.2s ease-in-out;
`;

declare type NavItem = {|
  href: string,
  active: boolean,
  count: number,
  translationKey: string,
|};

function ProfileHeaderWrapper(props: {
  user: EyeEmUser,
  isAuthUser: boolean,
  isEnterpriseCustomer: boolean,
  navItems: $ReadOnlyArray<NavItem>,
  isMobile: boolean,
}) {
  const { user } = props;
  const userThumbUrl =
    user.thumbUrl && !user.blocked && !user.restricted
      ? user.thumbUrl
      : '/node-static/img/profile-placeholder.jpg';

  const validUser =
    user &&
    (!user.blacklisted || props.isAuthUser) &&
    !user.blocked &&
    !user.restricted;

  const photoUrl =
    (validUser && user.coverPhoto && user.coverPhoto.previewUrl) || '';

  const getSanitizedHomepageUrl = (homepageUrl) => {
    if (homepageUrl.startsWith('http://')) {
      return homepageUrl.replace('http://', '');
    }
    if (homepageUrl.startsWith('https://')) {
      return homepageUrl.replace('https://', '');
    }
    return homepageUrl;
  };

  const button = !props.isEnterpriseCustomer && (
    <MainButton
      user={user}
      isAuthUser={props.isAuthUser}
      color="ghostWhite"
      size="small"
    />
  );

  const headline = (
    <Flex
      pb={2}
      alignItems="center"
      justifyContent={['center', 'center', 'left']}>
      {props.user && props.user.fullname && (
        <Text as="h1" variant="title4">
          {props.user.fullname || /* \u00A0 === &nbsp; */ '\u00A0'}
        </Text>
      )}
      {props.user && props.user.follower && (
        <Box pl={2}>
          <Tag text={t('user.followsYou')} />
        </Box>
      )}
    </Flex>
  );

  const subline = (
    <Box>
      <Flex
        justifyContent={['center', 'center', 'left']}
        flexDirection={['column', 'column', 'row']}
        alignItems="center">
        {props.user && props.user.nickname && (
          <Text as="h2">@{props.user.nickname}</Text>
        )}
        <Box>
          {props.navItems.map((navItem, index) => (
            <span key={`${navItem.href}-t`}>
              {props.isMobile && index === 0 ? null : (
                <Text key={`${navItem.href}-span`}>&nbsp;&middot;&nbsp;</Text>
              )}
              <Link href={navItem.href}>
                <StyledLinkText bold>
                  {t(`${navItem.translationKey}`, {
                    count: navItem.count,
                    abbreviatedCount:
                      navItem.count && abbreviate(navItem.count),
                  })}
                </StyledLinkText>
              </Link>
            </span>
          ))}
        </Box>
      </Flex>
      {props.user && props.user.description && !props.isMobile && (
        <Box pt={1}>
          <Text sx={{ color: 'rgba(255, 255, 255, .6)' }}>
            {props.user.description}
          </Text>
        </Box>
      )}
      {props.user && props.user.homepageUrl && (
        <Box pt={2} dataTestId={TEST_CREATOR_HEADER_HOMEPAGE}>
          <a
            rel="nofollow noreferrer"
            href={props.user.homepageUrl}
            target="_blank">
            <StyledLinkText bold>
              {getSanitizedHomepageUrl(props.user.homepageUrl)}
            </StyledLinkText>
          </a>
        </Box>
      )}
    </Box>
  );

  return (
    <ThemeProvider>
      <Header
        photoUrl={photoUrl}
        validResource={validUser}
        thumbUrl={userThumbUrl}
        headline={headline}
        subline={subline}
        button={button}
        photoAlt={props.user.fullname}
      />
    </ThemeProvider>
  );
}

export default ProfileHeaderWrapper;
