/* @flow */
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';

import { formValueSelector } from 'redux-form';

import { openModal } from '../../../helpers/actionCreators/redux';

import MultipleReleases from './multipleReleases.jsx';

import { ADD_MULTI_RELEASE } from '../../../graphql/mutations/release';

const reduxFormSelector = formValueSelector('multiRelease');

const mapStateToProps = (state) => {
  return {
    formValues: reduxFormSelector(state, 'firstName', 'lastName'),
  };
};

export default compose(
  graphql(ADD_MULTI_RELEASE, {
    name: 'addMultiRelease',
  }),
  connect(mapStateToProps, {
    openModal,
  })
)(MultipleReleases);
