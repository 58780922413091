/* @flow */

import type { Node } from 'react';
import { Danger } from '@eyeem-ui/icons';
import { Flex, Box } from '@eyeem-ui/atoms';
import { ThemeProvider } from '@eyeem-ui/theme';
import T from './translate.jsx';

function InfoBox(props: { title: string, text: string, button: Node }) {
  return (
    <ThemeProvider mode="light">
      <Box p="24px" bg="#e7f0fb">
        <Flex>
          <Box pr={2} flex="0 0 auto">
            <Danger size="24" />
          </Box>
          <Box>
            <T className="typo_p typo_p-bold typo-noMargin" component="h4">
              {props.title}
            </T>
            <T className="typo_pStatic" component="p">
              {props.text}
            </T>
            {props.button}
          </Box>
        </Flex>
      </Box>
    </ThemeProvider>
  );
}

export default InfoBox;
