/* @flow */
import { Component } from 'react';
import classnames from 'classnames';
import startsWith from 'lodash/startsWith';
import t from 'counterpart';

import {
  RELEASE_TYPE_MODEL,
  MULTIRELEASE_MODALSTATE_SELECT,
  MULTIRELEASE_MODALSTATE_NAME,
  MULTIRELEASE_MODALSTATE_CONFIRM,
} from '../../../constants/misc';

import Edit from './edit.jsx';
import ReleaseInfo from './releaseInfo.jsx';
import Confirm from './confirm.jsx';
import AskForReleaseModal from '../askForReleaseModal';

type Props = {
  openModal: OpenModal,
  className: string,
  close: CloseModal,
  formValues: {
    firstName: string,
    lastName: string,
  },
  fullScreen: boolean,
  addMultiRelease: Function,
  photos: $ReadOnlyArray<EyeEmPhoto>,
};

type State = {
  modalStep: MultiReleasesModalStep,
  release: UpdateReleasePayload,
  pending: boolean,
};

class MultipleReleases extends Component<Props, State> {
  constructor(props: Props, context: mixed) {
    super(props, context);
    this.state = {
      modalStep: props.modalStep || MULTIRELEASE_MODALSTATE_SELECT,
      release: {
        photoIds: [],
      },
      pending: false,
    };
  }

  updateReleasePayload = (payload: UpdateReleasePayload) =>
    this.setState({
      release: {
        // eslint-disable-next-line
        ...this.state.release,
        ...payload,
      },
    });

  setModalStep = (step: MultiReleasesModalStep) =>
    this.setState({ modalStep: step });

  handleReleaseCreated = () => {
    this.setState({
      pending: false,
    });

    const releaseTypeKey =
      this.state.release.releaseType === RELEASE_TYPE_MODEL
        ? 'modelRelease'
        : 'propertyRelease';

    return this.props.openModal({
      content: AskForReleaseModal,
      contentProps: {
        title: t(
          `pview.marketDetails.releaseManagement.askForRelease.uploadModal.title.${releaseTypeKey}`
        ),
        releaseId: this.state.release.multiReleaseId || this.props.state.id,
        fromMultiReleaseCreate: true,
        isMultiRelease: true,
      },
      useEUModal: true,
    });
  };

  addRelease = () => {
    if (!this.state.pending) {
      this.setState({
        pending: true,
      });

      this.props
        .addMultiRelease({
          variables: {
            payload: {
              ...this.state.release,
              releaseType: RELEASE_TYPE_MODEL,
            },
          },
        })
        .then(({ data }) => {
          const payload = {
            ...data.addMultiRelease,
            multiReleaseId: data.addMultiRelease.id,
          };
          this.updateReleasePayload(payload);
          return this.handleReleaseCreated();
        });
    }
  };

  render() {
    return (
      <div
        className={classnames(this.props.className, {
          'modal_main-fullScreen':
            this.props.fullScreen &&
            startsWith(this.state.modalStep, MULTIRELEASE_MODALSTATE_SELECT),
        })}>
        {startsWith(this.state.modalStep, MULTIRELEASE_MODALSTATE_SELECT) && (
          <Edit
            updateReleasePayload={this.updateReleasePayload}
            release={this.state.release}
            close={this.props.close}
            setModalStep={this.setModalStep}
            modalStep={this.state.modalStep}
            photos={this.props.photos}
          />
        )}
        {this.state.modalStep === MULTIRELEASE_MODALSTATE_NAME && (
          <ReleaseInfo
            updateReleasePayload={this.updateReleasePayload}
            close={this.props.close}
            initialValues={{
              firstName: this.state.release.firstName,
              lastName: this.state.release.lastName,
            }}
            formValues={this.props.formValues}
            setModalStep={this.setModalStep}
            release={this.state.release}
            photos={this.props.photos.filter((photo) =>
              this.state.release.photoIds.includes(photo.id)
            )}
          />
        )}
        {this.state.modalStep === MULTIRELEASE_MODALSTATE_CONFIRM && (
          <Confirm
            pending={this.state.pending}
            setModalStep={this.setModalStep}
            release={this.state.release}
            addRelease={this.addRelease}
            photos={this.props.photos.filter((photo) =>
              this.state.release.photoIds.includes(photo.id)
            )}
          />
        )}
      </div>
    );
  }
}

export default MultipleReleases;
