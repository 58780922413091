/* @flow */
import { Component } from 'react';
import styled from 'styled-components';
import t from 'counterpart';
import noop from 'lodash/noop';
import classnames from 'classnames';
import { Icon } from 'eyeem-components';
import { Text, Buttonizer, Box, Button, Flex } from '@eyeem-ui/atoms';
import { ThemeProvider } from '@eyeem-ui/theme';

import {
  MULTIRELEASE_MODALSTATE_SELECT,
  MULTIRELEASE_MODALSTATE_SELECT_EDIT,
  MULTIRELEASE_MODALSTATE_SELECT_VIEW,
  MULTIRELEASE_MODALSTATE_NAME,
  MULTIRELEASE_MODALSTATE_CONFIRM,
} from '../../../constants/misc';

import T from '../../general/translate.jsx';
import PlainHeader from '../../general/plainHeader.jsx';

import {
  preventAndStop,
  toggleSelectionIds,
  track,
} from '../../../helpers/tools';

import Selection from './selection/';

import type { SetModalStep } from './types';

const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const SelectionWrapper = styled.div`
  flex: 1;
  overflow: auto;
`;

type Props = {
  close: CloseModal,
  setModalStep: SetModalStep,
  modalStep: MultiReleasesModalStep,
  release: UpdateReleasePayload,
  updateReleasePayload: (payload: UpdateReleasePayload) => void,
  photos: $ReadOnlyArray<EyeEmPhoto>,
};

class EditMultipleReleases extends Component<Props> {
  toggleSelection = (item: { id: EyeEmAssetId }) =>
    this.props.updateReleasePayload({
      photoIds: toggleSelectionIds({
        map: this.props.release.photoIds,
        id: item.id,
      }),
    });

  enoughPhotosSelected = () => this.total() >= 2;

  setNameState = () => this.props.setModalStep(MULTIRELEASE_MODALSTATE_NAME);
  setConfirmState = () =>
    this.props.setModalStep(MULTIRELEASE_MODALSTATE_CONFIRM);

  deselectAllPhotos = () => this.props.updateReleasePayload({ photoIds: [] });

  isOnSelect = () => this.props.modalStep === MULTIRELEASE_MODALSTATE_SELECT;
  isOnEdit = () => this.props.modalStep === MULTIRELEASE_MODALSTATE_SELECT_EDIT;
  isOnView = () => this.props.modalStep === MULTIRELEASE_MODALSTATE_SELECT_VIEW;

  total = () =>
    this.props.updateReleasePayload && this.props.release.photoIds.length;

  belowSectionContent = () => (
    <div
      className={classnames({
        'multipleReleasesModalSubnav-standalone':
          this.isOnEdit() || this.isOnView(),
      })}>
      {this.isOnView() && (
        <span className="modal_close" onClick={this.setConfirmState}>
          <Icon size={18} type="close" color="greys.grey4" />
        </span>
      )}
      <div className="small-12 medium-6 columns multipleReleasesModalSubnav_left">
        <T
          count={this.total()}
          with={{
            number: (
              <span className="typo-inlineBlock typo_pStatic typo_pStatic-bold">
                {this.total()}
              </span>
            ),
          }}
          className="typo_pStatic typo-inline">
          multipleReleases.selectModal.photoCount
        </T>
        {this.total() > 0 && !this.isOnView() && (
          <Buttonizer
            onClick={preventAndStop({
              callback: this.deselectAllPhotos,
            })}>
            <ThemeProvider mode="light">
              <Box ml="3">
                <Text color="aqua50">
                  {t('multipleReleases.selectModal.deselectAll')}
                </Text>
              </Box>
            </ThemeProvider>
          </Buttonizer>
        )}
      </div>
      <div className="small-12 medium-6 columns multipleReleasesModalSubnav_right">
        <ThemeProvider mode="light">
          <Flex justifyContent="flex-end" alignItems="center">
            {!this.isOnView() && (
              <Box mr="2">
                <Button
                  tabIndex={-1}
                  variant="subtle"
                  onClick={() => {
                    track({
                      eventName: t(
                        'multipleReleases.header.buttons.cancel.eventName'
                      ),
                      eventAction: t('tracking.eventAction.button'),
                      eventLabel: t(
                        'multipleReleases.header.buttons.cancel.label'
                      ),
                      eventPosition: t('multipleReleases.header.eventPosition'),
                      eventType: t('tracking.eventType.inbound'),
                      eventOption: { photosSelected: this.total() },
                    });
                    this.isOnSelect()
                      ? this.props.close()
                      : this.setNameState();
                  }}>
                  {t('multipleReleases.header.buttons.cancel.label')}
                </Button>
              </Box>
            )}
            {this.isOnSelect() && (
              <Button
                onClick={() => {
                  track({
                    eventName: t(
                      'multipleReleases.header.buttons.next.eventName'
                    ),
                    eventAction: t('tracking.eventAction.button'),
                    eventLabel: t('multipleReleases.header.buttons.next.label'),
                    eventPosition: t('multipleReleases.header.eventPosition'),
                    eventType: t('tracking.eventType.inbound'),
                    eventOption: { photosSelected: this.total() },
                  });
                  this.enoughPhotosSelected() ? this.setNameState() : noop();
                }}
                disabled={!this.enoughPhotosSelected()}>
                {t('multipleReleases.header.buttons.next.label')}
              </Button>
            )}
            {this.isOnEdit() && (
              <Button
                disabled={!this.enoughPhotosSelected()}
                onClick={
                  this.enoughPhotosSelected() ? this.setNameState : noop
                }>
                {t('multipleReleases.header.buttons.done.label')}
              </Button>
            )}
          </Flex>
        </ThemeProvider>
      </div>
    </div>
  );

  render() {
    return (
      <ScrollWrapper>
        {this.isOnSelect() && (
          <ThemeProvider mode="light">
            <PlainHeader
              textLeftOnAllScreens
              headline="multipleReleases.selectModal.headline"
              text1="multipleReleases.selectModal.subline"
              belowSectionContent={this.belowSectionContent()}
            />
          </ThemeProvider>
        )}
        {(this.isOnEdit() || this.isOnView()) && this.belowSectionContent()}
        <SelectionWrapper>
          <Selection
            selectedPhotosOnly={this.isOnView()}
            stepSize={100}
            photoIds={this.props.release.photoIds}
            toggleSelection={this.isOnView() ? null : this.toggleSelection}
            photos={this.props.photos}
          />
        </SelectionWrapper>
      </ScrollWrapper>
    );
  }
}

export default EditMultipleReleases;
