/* @flow */
import { connect } from 'react-redux';

import { getIsBuyer, getIsSeller } from '../../../reducers/';

import MainButton from './mainButton.jsx';

const mapStateToProps = (state) => ({
  isBuyer: getIsBuyer(state),
  isSeller: getIsSeller(state),
});

export default connect(mapStateToProps)(MainButton);
