/* @flow */
import { Component } from 'react';
import t from 'counterpart';
import styled from 'styled-components';
import { Icon, styleUtils } from 'eyeem-components';
import { Form, Field } from 'react-final-form';
import { Box, Button } from '@eyeem-ui/atoms';
import { Arrow } from '@eyeem-ui/icons';
import { ThemeProvider } from '@eyeem-ui/theme';

import type { SetModalStep } from './types';

import T from '../../general/translate.jsx';
import SelectionStrip from '../../general/selectionStrip/';

import { Input } from '../../formElements/';

import {
  MULTIRELEASE_MODALSTATE_SELECT,
  MULTIRELEASE_MODALSTATE_SELECT_EDIT,
  MULTIRELEASE_MODALSTATE_CONFIRM,
} from '../../../constants/misc';

import {
  TEST_MODAL_CLOSE,
  TEST_MULTI_RELEASES_FIRSTNAME_INPUT,
  TEST_MULTI_RELEASES_LASTNAME_INPUT,
} from '../../../constants/pageObjectSelectors';

import validate, {
  VALIDATOR_UNICODE_STRING,
  VALIDATOR_REQUIRED,
  composeValidators,
} from '../../../helpers/validators';
import { track } from '../../../helpers/tools';

const required = validate(VALIDATOR_REQUIRED);
const unicodeString = validate(VALIDATOR_UNICODE_STRING);

const BackIcon = styled(T)`
  position: absolute;
  top: 16px;
  left: 24px;

  padding: 8px;
  cursor: pointer;
  fill: ${styleUtils.fromTheme('colors.greys.grey4')};
`;

const StyledHeader = styled.div`
  height: 40px;
`;

type Props = {
  close: CloseModal,
  release: EyeEmRelease,
  setModalStep: SetModalStep,
  updateReleasePayload: (payload: UpdateReleasePayload) => void,
  photos: $ReadOnlyArray<EyeEmPhoto>,
};

class ReleaseInfo extends Component<Props> {
  submit = (values: { firstName: string, lastName: string }) => {
    this.props.updateReleasePayload({
      ...this.props.release,
      ...values,
    });

    return this.props.setModalStep(MULTIRELEASE_MODALSTATE_CONFIRM);
  };

  previousStep = () => {
    this.props.updateReleasePayload({
      ...this.props.release,
      ...this.props.formValues,
    });
    return this.props.setModalStep(MULTIRELEASE_MODALSTATE_SELECT);
  };

  setViewState = () => {
    this.props.updateReleasePayload({
      ...this.props.release,
      ...this.props.formValues,
    });
    return this.props.setModalStep(MULTIRELEASE_MODALSTATE_SELECT_EDIT);
  };

  getEventOption = () => ({
    photosSelected: this.props.release.photoIds.length,
    type: 'model',
  });

  render() {
    return (
      <Form
        onSubmit={this.submit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <StyledHeader>
              <span
                data-test-id={TEST_MODAL_CLOSE}
                onClick={this.props.close}
                className="modal_close">
                <Icon size={18} type="close" color="greys.grey4" />
              </span>
              <BackIcon
                noTranslate
                onClick={this.previousStep}
                eventType={t('tracking.eventType.inbound')}
                eventName={t('multipleReleases.releaseInfo.back.eventName')}
                eventAction={t('tracking.eventAction.button')}
                eventPosition={t('multipleReleases.releaseInfo.eventPosition')}
                eventLabel={t('multipleReleases.releaseInfo.back.label')}
                eventOption={this.getEventOption()}>
                <ThemeProvider mode="light">
                  <Arrow size="24" rotate="left" color="ghost70" />
                </ThemeProvider>
              </BackIcon>
            </StyledHeader>
            <ThemeProvider mode="light">
              <Box mx={4}>
                <SelectionStrip
                  buttonAction={this.setViewState}
                  className="typo-topMarginBoost-small"
                  canEdit={!this.props.release.id}
                  eventOption={this.getEventOption()}
                  photos={this.props.photos}
                />
              </Box>
            </ThemeProvider>
            <div className="modal_content modal_content-noTop text-left">
              <T className="typo_pMin typo-color-grey3 typo-topMarginBoost-small">
                multipleReleases.releaseInfo.form.headline
              </T>
              <ThemeProvider mode="light">
                <div>
                  <Field
                    name="firstName"
                    type="text"
                    validate={composeValidators(required, unicodeString)}>
                    {({ input, meta }) => (
                      <Input
                        input={input}
                        meta={meta}
                        isValid={!meta.invalid && !meta.pristine}
                        type="text"
                        placeholder={t(
                          'multipleReleases.releaseInfo.form.labels.firstName'
                        )}
                        data-test-id={TEST_MULTI_RELEASES_FIRSTNAME_INPUT}
                        name="firstName"
                        errorMessage={meta.error}
                      />
                    )}
                  </Field>
                  <Field
                    name="lastName"
                    type="text"
                    validate={composeValidators(required, unicodeString)}>
                    {({ input, meta }) => (
                      <Input
                        input={input}
                        meta={meta}
                        isValid={!meta.invalid && !meta.pristine}
                        type="text"
                        placeholder={t(
                          'multipleReleases.releaseInfo.form.labels.lastName'
                        )}
                        data-test-id={TEST_MULTI_RELEASES_LASTNAME_INPUT}
                        name="lastName"
                        errorMessage={meta.error}
                      />
                    )}
                  </Field>
                </div>
              </ThemeProvider>
            </div>
            <div className="modal_buttonBox modal_buttonBox-seamless">
              <ThemeProvider>
                <Button
                  fullWidth
                  type="submit"
                  onClick={() => {
                    track({
                      eventType: t('tracking.eventType.inbound'),
                      eventName: t(
                        'multipleReleases.releaseInfo.next.eventName'
                      ),
                      eventAction: t('tracking.eventAction.button'),
                      eventPosition: t(
                        'multipleReleases.releaseInfo.eventPosition'
                      ),
                      eventLabel: t('multipleReleases.releaseInfo.next.label'),
                      eventOption: this.getEventOption(),
                    });
                  }}>
                  {t('multipleReleases.releaseInfo.next.label')}
                </Button>
              </ThemeProvider>
            </div>
          </form>
        )}
      />
    );
  }
}

export default ReleaseInfo;
