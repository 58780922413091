/* @flow */
import t from 'counterpart';
import styled from '@emotion/styled';
import { media, ThemeProvider } from '@eyeem-ui/theme';
import { Button, Flex, Box } from '@eyeem-ui/atoms';

import T from '../../general/translate.jsx';
import PlainHeader from '../../general/plainHeader.jsx';
import TooltipTrigger from '../../general/tooltipTrigger/';

import { TOOLTIP_POSITION_LEFT } from '../../../constants/misc';
import { preventAndStop, track } from '../../../helpers/tools';

import MultiReleasesModal from '../../modals/multipleReleases/';

import { TEST_MULTI_RELEASES_LINK } from '../../../constants/pageObjectSelectors';

const MultiReleasesLinkWrapper = styled(Flex)`
  @media ${media.mediumUp} {
    float: right;
  }
`;

function ReleasesHeader(props: {
  openModal: OpenModal,
  total: number,
  photos: $ReadOnlyArray<EyeEmPhoto>,
}) {
  const belowSectionContent = (
    <Flex
      justifyContent={['flex-start', 'space-between']}
      alignItems={['flex-start', 'center']}
      mt="2"
      flexDirection={['column', 'row']}>
      <Box>
        <T
          count={props.total}
          with={{
            number: (
              <span className="typo-inlineBlock typo_pStatic typo_pStatic-bold typo-color-grey3">
                {props.total}
              </span>
            ),
          }}
          className="typo_pStatic typo-color-grey3">
          profile.releasesHeader.photoCount
        </T>
      </Box>
      <Box mt={[3, 0]}>
        <MultiReleasesLinkWrapper>
          <Box mr={1}>
            <TooltipTrigger
              eventPosition={t('profile.releasesHeader.eventPosition')}
              panelPosition={TOOLTIP_POSITION_LEFT}
              content={[
                {
                  body: `profile.releasesHeader.tooltip.body`,
                },
              ]}
            />
          </Box>
          <Button
            dataTestId={TEST_MULTI_RELEASES_LINK}
            onClick={(event) => {
              track({
                eventType: t('tracking.eventType.inbound'),
                eventName: t('profile.releasesHeader.modalTrigger.eventName'),
                eventAction: t('tracking.eventAction.link'),
                eventLabel: t('profile.releasesHeader.modalTrigger.label'),
                eventPosition: t('profile.releasesHeader.eventPosition'),
              });
              preventAndStop({
                callback: props.openModal,
                payload: {
                  content: MultiReleasesModal,
                  contentProps: {
                    fullScreen: true,
                    photos: props.photos,
                  },
                },
              })(event);
            }}>
            {t('profile.releasesHeader.modalTrigger.label')}
          </Button>
        </MultiReleasesLinkWrapper>
      </Box>
    </Flex>
  );

  return (
    <ThemeProvider>
      <PlainHeader
        textLeftOnAllScreens
        onDark
        noBorder
        headline="profile.releasesHeader.headline"
        text1="profile.releasesHeader.subline"
        belowSectionContent={belowSectionContent}
      />
    </ThemeProvider>
  );
}

export default ReleasesHeader;
