/* @flow */
import { Component } from 'react';
import t from 'counterpart';
import styled from '@emotion/styled';
import { Box, Button, Flex } from '@eyeem-ui/atoms';
import { ThemeProvider } from '@eyeem-ui/theme';

import type { SetModalStep } from './types';

import InfoBox from '../../general/infoBox.jsx';
import SelectionStrip from '../../general/selectionStrip/';

import { track } from '../../../helpers/tools';
import {
  MULTIRELEASE_MODALSTATE_SELECT,
  MULTIRELEASE_MODALSTATE_SELECT_VIEW,
  MULTIRELEASE_MODALSTATE_NAME,
} from '../../../constants/misc';

const StyledHeader = styled.div`
  height: 40px;
`;

type Props = {
  setModalStep: SetModalStep,
  addRelease: Function,
  release: UpdateReleasePayload,
  pending: boolean,
  photos: $ReadOnlyArray<EyeEmPhoto>,
};

class Confirm extends Component<Props> {
  previousStep = () => this.props.setModalStep(MULTIRELEASE_MODALSTATE_SELECT);

  setSelectViewState = () =>
    this.props.setModalStep(MULTIRELEASE_MODALSTATE_SELECT_VIEW);

  setNameState = () => {
    track({
      eventType: t('tracking.eventType.inbound'),
      eventName: t('multipleReleases.confirm.edit.eventName'),
      eventAction: t('tracking.eventAction.button'),
      eventPosition: t('multipleReleases.confirm.eventPosition'),
      eventLabel: t('multipleReleases.confirm.edit.label'),
      eventOption: {
        photosSelected: this.props.release.photoIds.length,
        type: 'model',
      },
    });
    this.props.setModalStep(MULTIRELEASE_MODALSTATE_NAME);
  };

  onSubmit = () => {
    track({
      eventType: t('tracking.eventType.inbound'),
      eventName: t('multipleReleases.confirm.edit.eventName'),
      eventAction: t('tracking.eventAction.button'),
      eventPosition: t('multipleReleases.confirm.eventPosition'),
      eventLabel: t('multipleReleases.confirm.edit.label'),
      eventOption: {
        photosSelected: this.props.release.photoIds.length,
        type: 'model',
      },
    });
    this.props.addRelease();
  };

  render() {
    return (
      <div className="text-left">
        <StyledHeader />
        <ThemeProvider mode="light">
          <Box px={4}>
            <SelectionStrip
              buttonAction={this.setSelectViewState}
              setModalStep={this.props.setModalStep}
              firstName={this.props.release.firstName}
              lastName={this.props.release.lastName}
              photos={this.props.photos}
            />
          </Box>
        </ThemeProvider>
        <div className="modal_content modal_content-noTop">
          <InfoBox
            title="multipleReleases.confirm.infoBox.title"
            text="multipleReleases.confirm.infoBox.text"
          />
        </div>
        <div className="modal_buttonBox modal_buttonBox-seamless">
          <ThemeProvider mode="light">
            <Flex justifyContent="flex-end">
              <Box mr="2">
                <Button variant="subtle" onClick={this.setNameState}>
                  {t('multipleReleases.confirm.edit.label')}
                </Button>
              </Box>
              <Button
                loading={this.props.pending}
                disabled={this.props.pending}
                onClick={this.onSubmit}>
                {t('multipleReleases.confirm.next.label')}
              </Button>
            </Flex>
          </ThemeProvider>
        </div>
      </div>
    );
  }
}

export default Confirm;
