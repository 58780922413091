/* @flow */

import SelectGrid from '../../../general/selectGrid/';

type Props = {
  photoIds: $ReadOnlyArray<EyeEmAssetId>,
  toggleSelection: Function,
  selectedPhotosOnly: boolean,
  nickname: string,
  photos: $ReadOnlyArray<EyeEmPhoto>,
};

const Selection = (props: Props) => {
  return (
    <SelectGrid
      className="g_greyBackground_gradient"
      photos={
        props.selectedPhotosOnly
          ? props.photos.filter((item) => props.photoIds.includes(item.id))
          : props.photos
      }
      selection={props.selectedPhotosOnly ? false : props.photoIds}
      toggleSelection={props.toggleSelection}
    />
  );
};

export default Selection;
