// @flow
import t from 'counterpart';
import get from 'lodash/get';
import { Helmet } from 'react-helmet-async';

import withRouter from '../../helpers/hoc/withRouter';
import { getNoindexContentByLanguage } from '../../helpers/localization';
import { renderLinkTags } from '../../helpers/renderLinkTags';
import { truncateBeforeFirstWordThatExceeds } from '../../helpers/tools';

import {
  DESCRIPTION_MAX_LENGTH,
  DEFAULT_LANGUAGE,
  PRODUCTION_BASEURL,
} from '../../constants/misc';

type ProfileMetaProps = {
  isBuyer: boolean,
  language: string,
  paginatable: {
    items: Paginatable,
  } & EyeEmPhoto,
  user: EyeEmUser,
} & WithRouterProps;

function ProfileMeta({
  getPath,
  isBuyer,
  language,
  paginatable,
  query,
  user,
}: ProfileMetaProps) {
  if (!user) return null;
  const path = getPath();
  const baseUrl = PRODUCTION_BASEURL;
  const page = query.page;
  const image = user.thumbUrl.replace('sq/50', 'sq/200');

  const shouldNotIndex = () => {
    // ROBOTS NOINDEX RULES
    const hasNoPhotos = user.totalPhotos === 0;
    // getty images are less then 70% of total commercial
    const { marketTotals } = user;

    const hasNotEnoughMarketPhotosOnGetty = () => {
      if (marketTotals) {
        return marketTotals.partner / marketTotals.commercial < 0.7;
      }
      return false;
    };

    const getDateOneYearAgo = () => {
      const dateOneYearAgo = new Date();
      dateOneYearAgo.setFullYear(dateOneYearAgo.getFullYear() - 1);
      return new Date(dateOneYearAgo);
    };

    const hasNotUploadedInLastYear =
      new Date(get(paginatable, 'items[0].updated', 0)) < getDateOneYearAgo();

    const hasMarketPhotos =
      user.marketTotals.commercial + user.marketTotals.editorial > 0;

    return (
      hasNoPhotos ||
      hasNotEnoughMarketPhotosOnGetty ||
      hasNotUploadedInLastYear ||
      !hasMarketPhotos ||
      language !== DEFAULT_LANGUAGE ||
      path.indexOf('/following') !== -1 ||
      path.indexOf('/followers') !== -1
    );
  };

  const getDescription = () => {
    const userIdentifier = user.nickname || user.fullname || user.id || '';
    const descriptionArgs = {
      locale: language,
      user: userIdentifier,
      page,
    };

    let description = isBuyer
      ? t('meta.description.userMarketplace', descriptionArgs)
      : t('meta.description.user', descriptionArgs);

    if (path.indexOf('/market') !== -1 && !page) {
      description = t('meta.description.userMarketplace', descriptionArgs);
    } else if (path.indexOf('/market') !== -1 && page) {
      description = t(
        'meta.description.userMarketplacePaginated',
        descriptionArgs
      );
    } else if (path.indexOf('/photos') !== -1 && !page) {
      description = t('meta.description.user', descriptionArgs);
    } else if (path.indexOf('/photos') !== -1 && page) {
      description = t('meta.description.userPaginated', descriptionArgs);
    } else if (path.indexOf('/followers') !== -1) {
      description = t('meta.description.userFollowers', descriptionArgs);
    } else if (path.indexOf('/following') !== -1) {
      description = t('meta.description.userFollowing', descriptionArgs);
    } else if (page) {
      description = isBuyer
        ? t('meta.description.userMarketplacePaginated', descriptionArgs)
        : t('meta.description.userPaginated', descriptionArgs);
    }

    return truncateBeforeFirstWordThatExceeds(
      DESCRIPTION_MAX_LENGTH,
      description
    );
  };
  const description = getDescription();

  let title = '';
  const appendix = ' | EyeEm Photographer';
  const uniqIdentifier = user.nickname || user.id;
  if (path.indexOf('/market') !== -1 && !page) {
    title = `${uniqIdentifier} Marketplace`;
  } else if (path.indexOf('/market') !== -1 && page) {
    title = `Page ${page} | ${uniqIdentifier} Marketplace`;
  } else if (path.indexOf('/photos') !== -1 && !page) {
    title = `${uniqIdentifier}${appendix}`;
  } else if (path.indexOf('/photos') !== -1 && page) {
    title = `Page ${page} | ${uniqIdentifier}${appendix}`;
  } else if (path.indexOf('/followers') !== -1) {
    title = `Followers of ${uniqIdentifier}${appendix}`;
  } else if (path.indexOf('/following') !== -1) {
    title = `Following ${uniqIdentifier}${appendix}`;
  } else if (page) {
    title = isBuyer
      ? `Page ${page} | ${uniqIdentifier} Marketplace`
      : `Page ${page} | ${uniqIdentifier}${appendix}`;
  } else {
    title = isBuyer
      ? `${uniqIdentifier} Marketplace`
      : `${uniqIdentifier}${appendix}`;
  }

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="twitter:title" content={title} />
      <meta name="description" content={description} />
      {shouldNotIndex && (
        <meta name="robots" content={getNoindexContentByLanguage()} />
      )}
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="twitter:image" content={image} />
      <meta property="og:type" content="profile" />
      <meta property="twitter:card" content="gallery" />
      <meta
        property="og:title"
        content={user.fullname || `@${user.nickname}`}
      />
      <meta property="profile:first_name" content={user.fullname} />
      <meta property="profile:username" content={user.nickname} />
      <meta property="eyeemapp:Photos" content={user.totalPhotos} />
      <meta property="eyeemapp:Followers" content={user.totalFollowers} />
      <meta property="eyeemapp:Friends" content={user.totalFriends} />
      <meta
        property="twitter:app:url:iphone"
        content={`eyeem://open/u/${user.nickname}`}
      />
      <meta
        property="twitter:app:url:ipad"
        content={`eyeem://open/u/${user.nickname}`}
      />
      <meta
        property="twitter:app:url:googleplay"
        content={`${baseUrl}/u/${user.nickname}`}
      />
      <meta
        property="al:android:url"
        content={`eyeem://open/u/${user.nickname}`}
      />
      <meta property="al:ios:url" content={`eyeem://open/u/${user.nickname}`} />
      {renderLinkTags({
        language,
        path,
        query,
      })}
    </Helmet>
  );
}

export default withRouter(ProfileMeta);
